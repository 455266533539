import './App.css';
import { Route, Routes } from 'react-router-dom'
import Home from './pages/home/index.jsx'
import Contact from './pages/contact/index.jsx';
import Galerie from './pages/galerie/index.jsx';
import Politica from './pages/politica/index.jsx';
import Termeni from './pages/termeni/index.jsx';

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/galerie' element={<Galerie />} />
        <Route path='/politica-de-confidentialitate' element={<Politica />} />
        <Route path='/termeni-si-conditii' element={<Termeni />} />
      </Routes>
    </>
  );
}

export default App;
