import React from 'react'
import './index.css'
import { FaDollarSign, FaFileAlt, FaClock, FaCertificate } from "react-icons/fa";
import { FaArrowTrendUp } from "react-icons/fa6";
import { IoPersonSharp } from "react-icons/io5";

function Section2() {
    return (
        <div className='section2-main'>
            <h1>De ce să aplici</h1>
            <div className='section2-div-cards'>
                <div className='section2-card'>
                    <FaDollarSign size={'2.8rem'} />
                    <h2>Procent de până la 70%</h2>
                    <p>Obține veniturile tale aproape în totalitate</p>
                </div>
                <div className='section2-card'>
                    <FaClock size={'2.8rem'} />
                    <h2>Program flexibil</h2>
                    <p>Îți alegi propriul orar de muncă</p>
                </div>
                <div className='section2-card'>
                    <IoPersonSharp size={'2.8rem'} />
                    <h2>Alege cu cine lucrezi</h2>
                    <p>Îți alegi propriul trainer dedicat</p>
                </div>
                <div className='section2-card'>
                    <FaFileAlt size={'2.8rem'} />
                    <h2>Siguranță</h2>
                    <p>Contract legal de colaborare</p>
                </div>
                <div className='section2-card'>
                    <FaArrowTrendUp size={'2.8rem'} />
                    <h2>Creștere rapida</h2>
                    <p>Generare de trafic organic</p>
                </div>
                <div className='section2-card'>
                    <FaCertificate size={'2.8rem'} />
                    <h2>Experiență</h2>
                    <p>Peste 15 ani de experiență în domeniu</p>
                </div>
            </div>
        </div>
    )
}

export default Section2