import React from 'react'
import './index.css'
import { useNavigate } from 'react-router-dom'

function Section3() {

    const navigate = useNavigate()

    return (
        <div className='section3-main'>
            <div className='section3-left'>
                <h1>Cum aplici</h1>
                <p>Contactează-ne pe WhatsApp pentru o programare sau pentru alte întrebări</p>
            </div>
            <div className='section3-right'>
                <h2>0784 094 896</h2>
                <button onClick={() => window.open("https://wa.me/40784094896", '_blank', 'noopener,noreferrer')}>
                    WhatsApp
                </button>
        </div>
        </div >
    )
}

export default Section3