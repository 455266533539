import React from 'react'
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Section3 from '../home/section3'
import { Helmet } from 'react-helmet-async'

function Contact() {
    return (
        <>
            <Helmet>
                <title>MODELE BY ALEX CAPITAL | Contact</title>
                <meta name='description' content='Contact MODELE BY ALEX CAPITAL | 
                Contactează-ne pe WhatsApp pentru o programare sau pentru alte întrebări - 0784 094 896' />
                    <link rel="canonical" href="/contact" />
            </Helmet>
            <Navbar />
            <div style={{ marginTop: '80px' }}></div>
            <Section3 />
            <iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Bulevardul%20Unirii%2057,%20Bucure%C8%99ti,%20Rom%C3%A2nia+(My%20Business%20Name)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
            </iframe>
            <Footer />
        </>
    )
}

export default Contact